import React from 'react';
import {styled} from 'styled-components';
import {mediaQueries} from '../../modules/common';
import clockIllustrationDesktop from '../../images/illustrations/clock-desktop.webp';
import clockIllustrationMobile from '../../images/illustrations/clock-mobile.webp';
import Image from 'next/image';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    ${mediaQueries.lessThan('small')`         
        margin-left: unset;
        height: 90%;
    `}
`;

const DesktopImage = styled(Image)`
    object-fit: contain;

    @media (max-width: 768px) {
        display: none;
    }
`;

const MobileImage = styled(Image)`
    object-fit: contain;
    object-position: center;
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
`;

// SVG blur placeholder for green gradient
const svgBlurPlaceholderStr =
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZCIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YyZjlmNyIgLz48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2UwZjVmMCIgLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjZWVlZTQiIC8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4=';

const ClockPlaceholder = (): JSX.Element => {
    return (
        <Wrapper>
            <DesktopImage
                src={clockIllustrationDesktop}
                alt="Clock"
                fill
                sizes="(min-width: 769px) 100vw, 1px"
                placeholder="blur"
                blurDataURL={svgBlurPlaceholderStr}
            />
            <MobileImage
                src={clockIllustrationMobile}
                alt="Clock"
                fill
                sizes="(max-width: 768px) 100vw, 1px"
                placeholder="blur"
                blurDataURL={svgBlurPlaceholderStr}
            />
        </Wrapper>
    );
};

export {ClockPlaceholder};
